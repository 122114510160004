import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Team from '../images/team.jpg'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import FadeIn from 'react-fade-in'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Company = () => {
    return (
        <div>
            <Layout>
                <Seo title="Company" description="Company page Ricta Browser" />
                <div className=" animate-fade-in-up headerCompany bg-no-repeat bg-fixed bg-center bg-cover h-screen resize">
                    <div className="h-screen bg-opacity-50 bg-fixed bg-black flex items-center justify-center" style={{background:'rgba(0,0,0,0.5)'}}>
                        <div className="mx-2 text-center">
                            <h1 className="mb-4 text-gray-200 font-extrabold text-lg md:text-5xl">
                        Why choose us?
                            </h1>

                            <h1 className="text-gray-100 mb-3 font-extrabold xs:text-lg md:text-2xl">
                        Welcome to Ricta Technologies.<br/> We &apos; re dedicated to giving you the very best software tools,<br/> with a focus on simplicity, dependability, customer service and innovation. 
                            </h1>
                        </div>
                    </div>
                </div>
                <section className="grid grid-cols-1 md:grid-cols-2">
                    <FadeIn delay="100" transitionDuration="700">
                        <div className="m-auto">
                            <img src={Team}  alt="team" />
                        </div>
                    </FadeIn>
                    <div>
                        <h3 className="sm:text-3xl text-2xl text-mainColor my-4">Our Philosophy & Methodology </h3>
                        <hr className="horizontalLine"/>
                        <FadeIn delay="100" transitionDuration="700">
                            <div className="container">
                                <div className="left">
                                    <BusinessCenterIcon className="icons"/>
                                </div>
                                <div className="right">
                                    <p className="text-left text-textColor lg:text-lg">We are driven by a desire to create win-win situations for our customers. We want to bring innovation like enterprise software, SaaS, cloud and blockchain to the forensic online acquisition browser. 
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="left">
                                    <TrendingUpIcon className="icons"/>
                                </div>
                                <div className="right">
                                    <p className="text-left text-textColor lg:text-lg">We see the latest technological advancements as unique opportunities to raise the existing product quality up while driving costs down for the customer. 
                                    </p>
                                </div>
                            </div>
                        </FadeIn>

                    </div>

                </section>
                <section className="bg-bgColor  py-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 ">
                        <div className="flex justify-center items-center my-4 mx-2">
                            <div className="max-w-lg bg-white border-2 border-gray-300 p-6 rounded-lg tracking-wide shadow-lg">
                                <div id="header" className="flex items-center mb-4"> 
                                    <img alt="avatar" className="w-20 rounded-full border-2 border-gray-300" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&w=128&h=128&q=60&facepad=2" />
                                    <div id="header-text" className="leading-5 ml-6 sm">
                                        <h4 id="name" className="text-xl font-semibold">Riccardo T.</h4>
                                        <h5 id="job" className="font-semibold text-mainColor">Co-Founder & CEO</h5>
                                    </div>
                                </div>
                                <div className="text-left" id="quote">
                                    <q className="italic text-textColor">We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don &apos;t hesitate to contact us. Let your ideas contribute to next feature release.</q>
                                </div>
                                <div className="flex justify-center">
                                    <ul className="flex flex-row mt-4 space-x-2">
                                        <li>
                                            <a href="" className="flex items-center justify-center h-8 w-8 border rounded-full text-textColor border-gray-400">
                                                <FacebookIcon className="text-mainColor"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" className="flex items-center justify-center h-8 w-8 border rounded-full text-textColor border-gray-400">
                                                <TwitterIcon className="text-mainColor"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" className="flex items-center justify-center h-8 w-8 border rounded-full text-textColor border-gray-400">
                                                <LinkedInIcon className="text-mainColor"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center my-4 mx-2">

                            <div className="max-w-lg bg-white border-2 border-gray-300 p-6 rounded-lg tracking-wide shadow-lg">
                                <div id="header" className="flex items-center mb-4"> 
                                    <img alt="avatar" className="w-20 rounded-full border-2 border-gray-300" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&w=128&h=128&q=60&facepad=2" />
                                    <div id="header-text" className="leading-5 ml-6 sm">
                                        <h4 id="name" className="text-xl font-semibold">Silvia L.</h4>
                                        <h5 id="job" className="font-semibold text-mainColor">Co-Founder and CFO</h5>
                                    </div>
                                </div>
                                <div className="text-left" id="quote">
                                    <q className="italic text-textColor">We are a boutique company and every customer is important to us. We want to help you win time by offering you a tool that automates all the process parts that are boring and redundant...</q>
                                </div>
                                <div className="flex justify-center">
                                    <ul className="flex flex-row mt-4 space-x-2">
                                        <li>
                                            <a href="" className="flex items-center justify-center h-8 w-8 border rounded-full text-textColor border-gray-400">
                                                <FacebookIcon className="text-mainColor"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" className="flex items-center justify-center h-8 w-8 border rounded-full text-textColor border-gray-400">
                                                <TwitterIcon className="text-mainColor"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" className="flex items-center justify-center h-8 w-8 border rounded-full text-textColor border-gray-400">
                                                <LinkedInIcon className="text-mainColor"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )

}
export default Company